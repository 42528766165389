import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NET_LANGUAGES } from '@net-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'net-language-buttons',
  templateUrl: './language-buttons.component.html',
  styleUrls: ['./language-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageButtonsComponent implements OnInit {
  @Input() alignment: 'start' | 'center' | 'end' = 'end';
  public activeLanguage!: string;
  public languages = NET_LANGUAGES;

  constructor(
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeLanguage = this.translate.currentLang;

    this.translate.onLangChange.subscribe(() => {
      this.activeLanguage = this.translate.currentLang;
      this.cdRef.detectChanges();
    });
  }

  public getButtonClasses(): string[] {
    const classes = [];

    if (this.alignment === 'start') {
      classes.push('justify-start');
    }
    if (this.alignment === 'center') {
      classes.push('justify-center');
    }
    if (this.alignment === 'end') {
      classes.push('justify-end');
    }

    return classes;
  }

  public useLanguage(language: string): void {
    this.translate.use(language);
  }
}
