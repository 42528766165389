<div class="flex flex-row -px-5 print:hidden" [ngClass]="getButtonClasses()">
  <div
    *ngFor="let language of languages; let last = last"
    class="px-5 border-r border-black"
    [ngClass]="{ 'border-r border-black ': !last }"
  >
    <button
      (click)="useLanguage(language.key)"
      [ngClass]="{
        'underline underline-offset-2': activeLanguage === language.key
      }"
      class="hover:underline hover:underline-offset-2"
    >
      {{ language.label }}
    </button>
  </div>
</div>
